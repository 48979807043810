@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-container {
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  width: 200%; 
  animation: slide 20s linear infinite;
}

.carousel:hover {
  animation-play-state: paused;
}

.carousel-item {
  flex: 0 0 20%; 
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


::-webkit-scrollbar {
    width: 10px; /* width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: black; /* color of the scrollbar thumb */
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* background color of the scrollbar track */
}